import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import(
      './index/index.module'
    ).then(m => m.IndexPageModule)
  },
  { 
    path: 'index', loadChildren: () => import(
      './index/index.module'
    ).then(m => m.IndexPageModule) 
  },
  { 
    path: 'tabs-condiciones', loadChildren: () => import(
      './tabs-condiciones/tabs-condiciones.module'
    ).then(m => m.TabsCondicionesModule) 
  },
  {
    path: 'formulario-personal',
    loadChildren: () => import('./formulario-personal/formulario-personal.module').then( m => m.FormularioPersonalPageModule)
  },
  {
    path: 'formulario-licencia',
    loadChildren: () => import('./formulario-licencia/formulario-licencia.module').then( m => m.FormularioLicenciaPageModule)
  },
  {
    path: 'segundo-conductor',
    loadChildren: () => import('./segundo-conductor/segundo-conductor.module').then( m => m.SegundoConductorPageModule)
  },
  {
    path: 'formulario-segundo-conductor',
    loadChildren: () => import('./formulario-segundo-conductor/formulario-segundo-conductor.module').then( m => m.FormularioSegundoConductorPageModule)
  },
  {
    path: 'modalidad-entrega',
    loadChildren: () => import('./modalidad-entrega/modalidad-entrega.module').then( m => m.ModalidadEntregaPageModule)
  },
  {
    path: 'entrega',
    loadChildren: () => import('./entrega/entrega.module').then( m => m.EntregaPageModule)
  },
  {
    path: 'confirmar',
    loadChildren: () => import('./confirmar/confirmar.module').then( m => m.ConfirmarPageModule)
  },
  { 
    path: 'final', loadChildren: () => import(
      './final/final.module'
    ).then(m => m.FinalPageModule) 
  },
  { 
    path: '**', loadChildren: () => import(
      './enlase-nodisponible/enlase-nodisponible.module'
    ).then(m => m.EnlaseNodisponiblePageModule) 
  },
  
  
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, { 
        preloadingStrategy: PreloadAllModules, 
        useHash: false  
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }