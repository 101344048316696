export const environment = {
  production: false,
  gtagcode: 'GTM-P9GL26H',

  nombre_aplicacion : "WebApp Rentacar",
  _ssp: 'M#0AygZi36C9*EcPxNATUsB^V9WlZmUQJ^FrrJ$tUvvBQAnnftWS@DKfhmcrQ!*z',
  api: {
    //"ull_": "https://dev3.prosys.cl/~ycastillo/rentacar/api/",
    "ull_": "https://testing.rentacar.clubautomotriz.cl/api/",
    "pss_": "rentacar-"
  },

  apiurl: "https://proxyinspecciones.clubautomotriz.cl/webapp_autoinspeccion/bridge/",
  gtag_mode: true,
};